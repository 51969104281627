import { createContext, MutableRefObject } from 'react';

interface ScrollableBoxContextType {
  scrollableBoxRef: MutableRefObject<HTMLDivElement | undefined>;
}

const defaultValues: ScrollableBoxContextType = {
  scrollableBoxRef: { current: undefined },
};

export const ScrollableBoxContext = createContext<ScrollableBoxContextType>(defaultValues);
