import { useQuery } from '@tanstack/react-query';

import { Quiz } from '@/appTypes';
import { axiosInstance } from '@/services';

export const useGetLastFinishedQuiz = () => {
  const getLastFinishedQuizRequest = async () => {
    const response = await axiosInstance.get<Quiz | undefined>('/quizzes/last_quiz_finished/');

    if (response.status === 404) return null;

    return response.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['lastFinishedQuiz'],
    queryFn: getLastFinishedQuizRequest,
    // staleTime: 5000, // 5 seconds
    refetchOnMount: true,
  });

  return {
    lastFinishedQuiz: data,
    isLoading,
  };
};
