import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BoxWithTitle } from '@/components/elements';
import { Meta } from '@/components/layout';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { useConfirmRegistration } from '../../hooks';
import { Box } from '@mui/material';

export const RegistrationConfirmPage = () => {
  const { t } = useAppTranslation();
  const [searchParams] = useSearchParams();

  const { confirmRegistration, isLoading, data, status } = useConfirmRegistration();

  const tokenFromEmail = searchParams.get('token');

  useEffect(() => {
    if (tokenFromEmail && status === 'idle') confirmRegistration({ token: tokenFromEmail });
  }, [confirmRegistration, data, isLoading, status, tokenFromEmail]);

  const isError = status === 'error';
  const isSuccess = status === 'success';

  const title = !isError ? t('user:yourEmailHasBeenConfirmed') : t('user:probablyYourActivationLinkHasExpired');
  const description = !isError
    ? t('user:yourEmailHasBeenConfirmedDescription')
    : t('user:probablyYourActivationLinkHasExpiredDescription');

  return (
    <Box>
      <Meta titleKey="user:signUp" />
      <BoxWithTitle
        loading={isLoading}
        loadingLabel="user:weAreVerifyingYourAccount"
        title={title}
        description={description}
      >
        {isError && (
          <Button variant="contained" color="primary" href={Pages.registration}>
            {t('user:signUp')}
          </Button>
        )}
        {/* In case when user won't be redirected for some reason */}
        {isSuccess && (
          <Button variant="contained" color="primary" href={Pages.home}>
            {t('user:letsTakeYourFirstQuiz')}
          </Button>
        )}
      </BoxWithTitle>
    </Box>
  );
};
