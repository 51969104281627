import { SplashScreen } from '@capacitor/splash-screen';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { CookiesServicesProvider } from '@/features/CookiesConsent';
import { ForceUpdateModal } from '@/features/ForceUpdate/components/ForceUpdateModal';
import { useAppSelector } from '@/hooks';
import { useNativePushNotifications, useTrackPageViewsForAnalytics } from '@/services';
import { MaterialThemeProvider } from '@/styles/theme';
import { useAppTranslation, useLoadOnceUserAndDeviceLanguage } from '@/translation';

import { AppUrlListener } from './AppUrlListener.native';
import { PageLayout } from '@/components/layout/PageLayout';
import { useHandleNativeBackAction } from './useHandleNativeBackAction';

export const AppWrappersInsideRouter = () => {
  const { i18n } = useAppTranslation();
  const isCurrentSessionExist = !!useAppSelector((state) => state.user.session);

  useTrackPageViewsForAnalytics();
  useNativePushNotifications();
  useLoadOnceUserAndDeviceLanguage();
  useHandleNativeBackAction();

  useEffect(() => {
    if (i18n.initializedLanguageOnce && isCurrentSessionExist) SplashScreen.hide();
  }, [i18n.initializedLanguageOnce, isCurrentSessionExist]);

  return (
    <div>
      <AppUrlListener />
      <MaterialThemeProvider>
        <CookiesServicesProvider>
          <PageLayout>
            <Outlet />
          </PageLayout>
        </CookiesServicesProvider>
        <ForceUpdateModal />
      </MaterialThemeProvider>
    </div>
  );
};
