import { BackButtonListenerEvent, App as CapacitorApp } from '@capacitor/app';
import { useAppNavigate, useIsPage } from '@/hooks';
import { useEffect } from 'react';
import { Pages } from './pages';
import { useSearchParams } from 'react-router-dom';
import { isIos, isNative } from '@/utils';
import { SwipeBackPlugin } from '@/capacitorPlugins/SwipeBack';

export const useHandleNativeBackAction = () => {
  const [searchParams] = useSearchParams();
  const { navigate } = useAppNavigate();

  const isHomePage = useIsPage([Pages.home]);
  const isLoginPage = useIsPage([Pages.login]);
  const isQuizSummaryPage = useIsPage([Pages.quizSummary]);
  const isSubscriptionPaymentStatusPage = useIsPage([Pages.userSubscriptionPaymentStatus]);

  const isUserComeFromQuizOnQuizSummaryPage = searchParams.get('fromQuiz') === 'true';
  const closeAppOnBack = isHomePage || isLoginPage;
  const canNotGoBack = isHomePage || isSubscriptionPaymentStatusPage;

  useEffect(() => {
    if (!isIos) return;

    if (canNotGoBack) SwipeBackPlugin?.disable();
    else SwipeBackPlugin?.enable();
  }, [canNotGoBack]);

  useEffect(() => {
    const handleBackButton = ({ canGoBack }: BackButtonListenerEvent) => {
      if (closeAppOnBack) {
        CapacitorApp.exitApp();
        return;
      }

      if (isQuizSummaryPage && isUserComeFromQuizOnQuizSummaryPage) {
        navigate(Pages.quizCreator);
        return;
      }
      if (canNotGoBack) return;

      if (canGoBack) window.history.back();
      else CapacitorApp.exitApp();
    };

    if (isNative) CapacitorApp.addListener('backButton', handleBackButton);

    return () => {
      if (isNative) CapacitorApp.removeAllListeners();
    };
  }, [canNotGoBack, closeAppOnBack, isQuizSummaryPage, isUserComeFromQuizOnQuizSummaryPage, navigate]);
};
