import { Navigate, createBrowserRouter } from 'react-router-dom';

import { HomePage } from '@/features/Home';
import {
  LoginPage,
  RecoverPasswordPage,
  RegistrationConfirmPage,
  RegistrationPage,
  RegistrationSuccessPage,
  UserProfilePage,
} from '@/features/User';
import { RemovedAccountPage } from '@/features/User/routes/RemovedAccountPage';
import { ResetPasswordPage } from '@/features/User/routes/ResetPasswordPage';

import { AppWrappersInsideRouter } from './AppWrappersInsideRouter';
import { ProtectedRoute } from './ProtectedRoute';
import { RouterErrorHandler } from './RouterErrorHandler';
import {
  LazyAssistantChatPage,
  LazyDemoPage,
  LazyQuizHistoryPage,
  LazyQuizCreatorPage,
  LazyQuizPage,
  LazyQuizSummaryPage,
  LazySubscriptionPaymentStatusPage,
  LazySubscriptionSummaryPage,
} from './lazyLoad';
import { Pages } from './pages';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppWrappersInsideRouter />,
    errorElement: <RouterErrorHandler />,
    children: [
      {
        index: true,
        element: <Navigate to={Pages.home} />,
      },
      {
        path: '*',
        element: <Navigate to={Pages.home} />,
      },
      {
        path: Pages.login,
        element: <LoginPage />,
      },
      {
        path: Pages.demo,
        element: <LazyDemoPage />,
      },
      {
        path: Pages.removedAccount,
        element: <RemovedAccountPage />,
      },
      {
        path: Pages.recoverPassword,
        element: <RecoverPasswordPage />,
      },
      {
        path: Pages.resetPassword,
        element: <ResetPasswordPage />,
      },
      {
        path: Pages.registration,
        element: <RegistrationPage />,
      },
      {
        path: Pages.registrationSuccess,
        element: <RegistrationSuccessPage />,
      },
      {
        path: Pages.registrationConfirm,
        element: <RegistrationConfirmPage />,
      },
      {
        path: Pages.home,
        element: (
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        ),
      },
      {
        path: Pages.quizCreator,
        element: (
          <ProtectedRoute>
            <LazyQuizCreatorPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Pages.quizSolving,
        element: (
          <ProtectedRoute>
            <LazyQuizPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Pages.quizSummary,
        element: (
          <ProtectedRoute>
            <LazyQuizSummaryPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Pages.quizHistory,
        element: (
          <ProtectedRoute>
            <LazyQuizHistoryPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Pages.userProfile,
        element: (
          <ProtectedRoute>
            <UserProfilePage />
          </ProtectedRoute>
        ),
      },
      {
        path: Pages.userSubscriptionSummary,
        element: (
          <ProtectedRoute>
            <LazySubscriptionSummaryPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Pages.userSubscriptionPaymentStatus,
        element: (
          <ProtectedRoute>
            <LazySubscriptionPaymentStatusPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Pages.assistantChat,
        element: (
          <ProtectedRoute>
            <LazyAssistantChatPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);
