import { Box, Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Subscriptions } from '@/appTypes/Subscription';
import { DataWithLabel, Text } from '@/components/elements';
import { ConfirmationModal } from '@/components/elements/ConfirmationModal';
import { Meta } from '@/components/layout';
import { planFullName, planColor, formatExpirationDate } from '@/features/Subscription/utils';
import { useAppSelector } from '@/hooks';
import { Pages, useGetPagePathWithLang } from '@/navigation';
import { TranslationKeys, useAppTranslation } from '@/translation';

import { UserProfileLanguageSelector } from '../../components';
import { useRemoveAccount, useRefreshCurrentUserData } from '../../hooks';

import { ChangePasswordForm } from './ChangePasswordForm';
import { UserDataForm } from './UserDataForm';

export const UserProfilePage = () => {
  const { refreshCurrentUserData } = useRefreshCurrentUserData();
  const { currentUser } = useAppSelector((state) => state.user);
  const { t } = useAppTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_searchParams, setSearchParams] = useSearchParams();

  const termsOfServicePath = useGetPagePathWithLang(Pages.termsOfService);
  const privacyPolicyPath = useGetPagePathWithLang(Pages.privacyPolicy);

  const { isLoading: isLoadingRemovingAccount, removeAccount } = useRemoveAccount();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    refreshCurrentUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentPlan = currentUser?.subscription?.type || Subscriptions.FREE;
  const currentPlanName = t(planFullName[currentPlan]) as TranslationKeys;

  const expirationDate = formatExpirationDate(currentUser?.subscription?.expirationDate);

  const showCookiesConsent = () => {
    setSearchParams({ showCookiesSettings: 'true' });
  };

  const isCurrentPlanFree = currentPlan === Subscriptions.FREE;
  const planButtonVariant = isCurrentPlanFree ? 'contained' : 'text';

  return (
    <Box>
      <Meta titleKey="common:profile" />
      <Stack mt={8} rowGap={10} columnGap={16} direction="row" flexWrap="wrap">
        <Stack spacing={4}>
          <Text variant="titleLarge" translationKey="user:subscription" />
          <DataWithLabel
            labelKey="user:currentPlan"
            data={currentPlanName}
            dataColor={planColor[currentUser?.subscription?.type || Subscriptions.FREE]}
          />
          {expirationDate && <DataWithLabel labelKey="user:expirationDate" data={expirationDate} />}
          <Button
            variant={planButtonVariant}
            color="primary"
            href={Pages.userSubscriptionSummary}
            sx={!isCurrentPlanFree ? { pl: 0 } : {}}
          >
            {t(isCurrentPlanFree ? 'user:upgradeToPro' : 'common:extendPlan')}
          </Button>
        </Stack>
        <UserProfileLanguageSelector />
        <UserDataForm />
        <ChangePasswordForm />
      </Stack>
      <Stack direction="row" justifyContent="center" mt={16} spacing={6}>
        <Button variant="text" color="info" onClick={showCookiesConsent}>
          {t('common:cookiesFilesSettings')}
        </Button>
        <Button variant="text" color="error" onClick={() => setIsModalOpen(true)}>
          {t('user:removeAccount')}
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="center" mt={6} spacing={6}>
        <Button variant="text" color="info" href={termsOfServicePath} target="_blank">
          {t('common:applicationTermsAndConditions')}
        </Button>
        <Button variant="text" color="info" href={privacyPolicyPath} target="_blank">
          {t('common:privacyPolicy')}
        </Button>
      </Stack>

      <ConfirmationModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={removeAccount}
        title={t('user:removingAccount')}
        description={t('user:removingAccountDescription')}
        dangerous
        loading={isLoadingRemovingAccount}
      />
    </Box>
  );
};
