import { Stack } from '@mui/material';
import { useEffect } from 'react';

import { useRefreshCurrentUserData } from '@/features/User';
import { isWeb } from '@/utils';

import { RenewPlanBox, ExpiringPlanBox, AssistantChatBox, QuizQuickStartWithSubInfoSection } from '../../components';
import { useShowPlanBoxes } from '../../hooks/useShowPlanBoxes';
import { MobileAppReleaseSection } from '../../components/MobileAppReleaseSection';
import { Box } from '@mui/material';

export const HomePage = () => {
  const { refreshCurrentUserData } = useRefreshCurrentUserData();
  const { showExpiringPlanBox, showRenewPlanBox } = useShowPlanBoxes();

  useEffect(() => {
    refreshCurrentUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Stack spacing={10}>
        {isWeb && <MobileAppReleaseSection />}

        {/* Plan sections */}
        {(showRenewPlanBox || showExpiringPlanBox) && (
          <Stack spacing={4}>
            <RenewPlanBox />
            <ExpiringPlanBox />
          </Stack>
        )}

        <QuizQuickStartWithSubInfoSection />
        <AssistantChatBox />
      </Stack>
    </Box>
  );
};
