import { Text } from '@/components/elements';
import { Surface } from '@/components/layout';
import { Button, LinearProgress, Skeleton, Stack } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';
import { Box } from '@mui/material';
import { totalQuestionsAmount } from '@/features/Quiz/constants';
import { LoadingButton } from '@mui/lab';
import { useCreateQuizLikeLastFinished, useResumeQuiz } from '@/features/Quiz/hooks';
import { useAppSelector } from '@/hooks';
import { Subscriptions } from '@/appTypes/Subscription';

export const QuizQuickStartWithSubInfoSection = () => {
  const { t } = useAppTranslation();
  const { currentUser } = useAppSelector((state) => state.user);

  const { isLoading: isLoadingResumeQuizData, lastUnfinishedQuiz, resumeQuiz } = useResumeQuiz();
  const {
    createQuizLikeLastFinished,
    isCreatingSimilar,
    isLoading: isLoadingCreatingSimilarData,
    canCreateSimilarQuiz,
  } = useCreateQuizLikeLastFinished();

  const isLoadingData = isLoadingResumeQuizData || isLoadingCreatingSimilarData;
  const isQuizToResume = !!lastUnfinishedQuiz;
  const showPromptToBuySubscription =
    !!currentUser &&
    currentUser.seenFreeQuestionsPercentage > 30 &&
    currentUser.subscription?.type === Subscriptions.FREE;
  const freeQuestionsDatabaseKnownPercentage = Math.round(currentUser?.seenFreeQuestionsPercentage || 0);

  return (
    <Box>
      <Surface
        variant="surface4"
        spacing={4}
        sx={{
          borderBottomLeftRadius: showPromptToBuySubscription ? 0 : undefined,
          borderBottomRightRadius: showPromptToBuySubscription ? 0 : undefined,
        }}
      >
        <Stack spacing={2}>
          {isLoadingData && (
            <>
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" width={200} sx={{ height: [40, 20] }} />
            </>
          )}
          {isQuizToResume && !isLoadingData && (
            <>
              <Text variant="headlineSmall" translationKey="quiz:unfinishedQuiz" />
              <Text variant="bodyMedium" translationKey="quiz:unfinishedQuizBody" />
            </>
          )}
          {!isQuizToResume && !isLoadingData && (
            <>
              <Text variant="headlineSmall" translationKey="quiz:createQuiz" />
              <Text variant="bodyMedium" translationKey="quiz:createQuizDescription" />
            </>
          )}
        </Stack>

        <Stack
          direction={'row'}
          justifyContent={['space-between', 'right']}
          alignItems={'center'}
          columnGap={6}
          rowGap={4}
        >
          <Button variant="outlined" href={Pages.quizCreator}>
            {t('quiz:creator')}
          </Button>
          {(isQuizToResume || canCreateSimilarQuiz) && (
            <LoadingButton
              variant="contained"
              endIcon={<PlayArrowIcon />}
              loading={isLoadingData || isCreatingSimilar}
              onClick={isQuizToResume ? resumeQuiz : createQuizLikeLastFinished}
            >
              {t(isQuizToResume ? 'quiz:resumeQuiz' : 'quiz:createSimilarQuiz')}
            </LoadingButton>
          )}
        </Stack>
      </Surface>
      {showPromptToBuySubscription && (
        <Surface variant="secondaryContainer" spacing={4} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
          <Stack spacing={1}>
            <Text
              variant="titleMedium"
              translationKey="quiz:youHaveAlreadyExploredNTheFreeDatabase"
              translationParams={{ number: freeQuestionsDatabaseKnownPercentage }}
            />
            <LinearProgress variant="determinate" color="secondary" value={freeQuestionsDatabaseKnownPercentage} />
          </Stack>
          <Text>
            <Text
              variant="bodyMedium"
              translationKey="quiz:getFullAccessToAllAppFeaturesAndTheEntireDatabaseOfNQuestionsPart1"
            />{' '}
            <Text
              variant="bodyMedium"
              fontWeight={700}
              translationKey="quiz:getFullAccessToAllAppFeaturesAndTheEntireDatabaseOfNQuestionsPart2"
              translationParams={{ number: totalQuestionsAmount }}
            />
          </Text>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" color="secondary" href={Pages.userSubscriptionSummary}>
              {t('common:getPro')}
            </Button>
          </Box>
        </Surface>
      )}
    </Box>
  );
};
