import { useGetLastUnfinishedQuiz } from '@/features/Home/api';
import { QuizGtmEvent, trackEvent, trackEventQuiz } from '@/services';
import { getFirstUnansweredQuestionIndex } from '../utils';
import { setInitialStateOnNewQuiz } from '../store';
import { useAppDispatch, useAppNavigate } from '@/hooks';
import { Pages } from '@/navigation';

export const useResumeQuiz = () => {
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigate();
  const { lastUnfinishedQuiz, isLoading } = useGetLastUnfinishedQuiz();

  const resumeQuiz = () => {
    if (!lastUnfinishedQuiz) return;

    trackEventQuiz(QuizGtmEvent.resumeQuiz);
    trackEvent.quiz.resumeQuiz(lastUnfinishedQuiz.id);
    const firstUnansweredQuestion = (getFirstUnansweredQuestionIndex(lastUnfinishedQuiz) || 0) + 1;

    dispatch(setInitialStateOnNewQuiz({ quiz: lastUnfinishedQuiz }));
    navigate(Pages.quizSolving, { id: lastUnfinishedQuiz.id }, { question: firstUnansweredQuestion.toString() });
  };

  return { lastUnfinishedQuiz, isLoading, resumeQuiz };
};
