import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import { Box, ButtonBase, Stack } from '@mui/material';
import { zIndex } from '@/constants/zIndex';
import { useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import { TranslationKeys } from '@/translation';
import { useAppNavigate, useIsPage } from '@/hooks';
import { Pages } from '@/navigation';
import { Text } from '../elements';

enum Tabs {
  home,
  aiAssistant,
  quizzes,
  profile,
}

export const BottomNavigation = () => {
  const { palette } = useTheme();
  const { navigate } = useAppNavigate();

  const isHomePage = useIsPage([Pages.home]);
  const isAiAssistantPage = useIsPage([Pages.assistantChat]);
  const isQuizzesPage = useIsPage([Pages.quizCreator, Pages.quizSolving, Pages.quizSummary, Pages.quizHistory]);
  const isProfilePage = useIsPage([Pages.userProfile]);

  const getCurrentTabBasedOnCurrentPage = useCallback(() => {
    if (isHomePage) return Tabs.home;
    if (isAiAssistantPage) return Tabs.aiAssistant;
    if (isQuizzesPage) return Tabs.quizzes;
    if (isProfilePage) return Tabs.profile;
    return Tabs.home;
  }, [isHomePage, isAiAssistantPage, isQuizzesPage, isProfilePage]);

  const [currentTab, setCurrentTab] = useState(getCurrentTabBasedOnCurrentPage());

  useEffect(() => {
    setCurrentTab(getCurrentTabBasedOnCurrentPage());
  }, [getCurrentTabBasedOnCurrentPage]);

  const changeTab = (tab: Tabs) => {
    switch (tab) {
      case Tabs.home:
        navigate(Pages.home);
        break;
      case Tabs.aiAssistant:
        navigate(Pages.assistantChat);
        break;
      case Tabs.quizzes:
        navigate(Pages.quizCreator);
        break;
      case Tabs.profile:
        navigate(Pages.userProfile);
        break;
    }
  };

  const buttons: TabButtonProps[] = [
    {
      icon: HomeIcon,
      onClick: () => changeTab(Tabs.home),
      isActive: currentTab === Tabs.home,
      textKey: 'common:home',
    },
    {
      icon: AutoAwesomeIcon,
      onClick: () => changeTab(Tabs.aiAssistant),
      isActive: currentTab === Tabs.aiAssistant,
      textKey: 'common:aiAssistant',
    },
    {
      icon: SchoolIcon,
      onClick: () => changeTab(Tabs.quizzes),
      isActive: currentTab === Tabs.quizzes,
      textKey: 'common:quizzes',
    },
    {
      icon: PersonIcon,
      onClick: () => changeTab(Tabs.profile),
      isActive: currentTab === Tabs.profile,
      textKey: 'common:profile',
    },
  ];

  return (
    <Box
      sx={{
        zIndex: zIndex.bottomNavigation,
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        spacing={0}
        sx={{ bgcolor: palette.app.background, borderTop: `1px solid ${palette.divider}` }}
      >
        {buttons.map((button, index) => (
          <TabButton key={index} {...button} />
        ))}
      </Stack>
    </Box>
  );
};

interface TabButtonProps {
  icon: React.ElementType;
  onClick: () => void;
  isActive: boolean;
  textKey: TranslationKeys;
}
const TabButton = ({ icon: Icon, onClick, isActive, textKey }: TabButtonProps) => {
  const { palette } = useTheme();

  return (
    <ButtonBase
      onClick={onClick}
      sx={{ width: '25%', padding: 1 }}
      TouchRippleProps={{
        center: true,
      }}
    >
      <Stack direction="column" alignItems="center" spacing={1}>
        <Icon sx={{ fontSize: 25, color: isActive ? palette.app.onSurface : palette.app.outline }} />
        <Text
          variant="labelSmall"
          translationKey={textKey}
          fontWeight={isActive ? 'bold' : 'normal'}
          sx={{
            color: isActive ? palette.app.onSurface : palette.app.outline,
          }}
        />
      </Stack>
    </ButtonBase>
  );
};
