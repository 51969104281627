import { Box, Button, Stack } from '@mui/material';
import { useEffect } from 'react';

import { BoxWithTitle, InTextButton, Text } from '@/components/elements';

import { useNotify } from '@/hooks';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { useLogout } from '../hooks';

export const RemovedAccountPage = () => {
  const { t } = useAppTranslation();
  const { logout } = useLogout();
  const { notify } = useNotify();

  useEffect(() => {
    logout();
  }, [logout]);

  const copyEmailToClipboard = () => {
    navigator.clipboard.writeText('kontakt@refereewise.com');
    notify('info', t('user:emailCopiedToClipboard'));
  };

  return (
    <Box>
      <BoxWithTitle title={t('user:yourAccountHasBeenRemoved')}>
        <Stack spacing={2} mb={4}>
          <Text variant="bodyMedium" translationKey="user:yourAccountHasBeenRemovedDescriptionPart1" />
          <Text variant="bodyMedium" translationKey="user:yourAccountHasBeenRemovedDescriptionPart2" />
          <Box lineHeight={1}>
            <Text variant="bodyMedium" translationKey="user:yourAccountHasBeenRemovedDescriptionPart3" />
            <InTextButton onClick={copyEmailToClipboard}>{'kontakt@refereewise.com'}</InTextButton>
            <Text variant="bodyMedium" translationKey="user:yourAccountHasBeenRemovedDescriptionPart4" />
          </Box>
        </Stack>
        <Button variant="contained" color="primary" href={Pages.landing}>
          {t('common:toHomePage')}
        </Button>
      </BoxWithTitle>
    </Box>
  );
};
