import { Box, Container } from '@mui/material';
import { BackButton, BackToHomePageButton, Header } from '../elements';
import { isNative, isWeb } from '@/utils';
import { BottomNavigation } from './BottomNavigation';
import { useIsPage } from '@/hooks';
import { Pages } from '@/navigation';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { ScrollableBoxContext } from './ScrollableBoxContext';

interface Props {
  children: React.ReactNode;
}

export const PageLayout = ({ children }: Props) => {
  const [searchParams] = useSearchParams();

  const { key: locationKey } = useLocation();

  const scrollableBoxRef = useRef<HTMLDivElement>();

  const isHomePage = useIsPage([Pages.home]);
  const isAssistantChatPage = useIsPage([Pages.assistantChat]);
  const isQuizCreatorPage = useIsPage([Pages.quizCreator]);
  const isQuizHistoryPage = useIsPage([Pages.quizHistory]);
  const isQuizSolvingPage = useIsPage([Pages.quizSolving]);
  const isQuizSummaryPage = useIsPage([Pages.quizSummary]);
  const isSubscriptionSummaryPage = useIsPage([Pages.userSubscriptionSummary]);
  const isUserProfilePage = useIsPage([Pages.userProfile]);
  const isUnauthorizedPage = useIsPage([
    Pages.login,
    Pages.registration,
    Pages.registrationSuccess,
    Pages.resetPassword,
    Pages.recoverPassword,
    Pages.demo,
  ]);

  const isUserComeFromQuizOnQuizSummaryPage = searchParams.get('fromQuiz') === 'true';

  const showBackButton =
    (isQuizSummaryPage && !isUserComeFromQuizOnQuizSummaryPage) || isSubscriptionSummaryPage || isQuizHistoryPage;
  const showHomeButtonOnWeb =
    isWeb && (isQuizSolvingPage || (isQuizSummaryPage && isUserComeFromQuizOnQuizSummaryPage));
  const showBottomNavigationOnMobile =
    isNative && (isHomePage || isAssistantChatPage || isQuizCreatorPage || isUserProfilePage);
  const removeBottomPadding = isAssistantChatPage || isQuizHistoryPage;
  const showHeader = !isUnauthorizedPage;

  useEffect(() => {
    const scrollTopOnEntry = !(isQuizSolvingPage || isQuizSummaryPage);
    if (scrollTopOnEntry) scrollableBoxRef.current?.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [locationKey, isQuizSolvingPage, isQuizSummaryPage]);

  return (
    <ScrollableBoxContext.Provider value={{ scrollableBoxRef }}>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))"
      >
        <Box
          ref={scrollableBoxRef}
          flex={1}
          width="100%"
          overflow={'auto'}
          id="scrollable-box"
          sx={{
            minHeight: 0, // Fix flexbox scrolling issue
            WebkitOverflowScrolling: 'touch', // Smooth scrolling on iOS
            pb: removeBottomPadding ? 0 : 8,
          }}
        >
          <Container maxWidth="md" sx={{ px: 0 }}>
            {showHeader && <Header showBackButtonOnMobile={showBackButton && isNative} />}

            {showBackButton && isWeb && (
              <Box mb={3}>
                <BackButton />
              </Box>
            )}
            {showHomeButtonOnWeb && (
              <Box mb={3}>
                <BackToHomePageButton />
              </Box>
            )}

            <Box px={3}>{children}</Box>
          </Container>
        </Box>

        {showBottomNavigationOnMobile && isNative && <BottomNavigation />}
      </Box>
    </ScrollableBoxContext.Provider>
  );
};
