import { useGetLastFinishedQuiz } from '@/features/Home/api';
import { QuizGtmEvent, trackEventQuiz } from '@/services';
import { useCreateQuiz } from './useCreateQuiz';
import { QuizMode } from '@/appTypes';

export const useCreateQuizLikeLastFinished = () => {
  const { isLoading, lastFinishedQuiz } = useGetLastFinishedQuiz();
  const { createQuiz, isLoading: isCreatingSimilar } = useCreateQuiz();

  const createQuizLikeLastFinished = async () => {
    if (!lastFinishedQuiz) return;

    trackEventQuiz(QuizGtmEvent.createSimilarOnHomePage, {
      questionsAmount: lastFinishedQuiz.questionsAmount,
      quizMode: lastFinishedQuiz.mode,
    });
    createQuiz({
      selectedArticlesNumbers: lastFinishedQuiz.relatedArticles || [],
      questionNumber: lastFinishedQuiz.questionsAmount || 10,
      mode: lastFinishedQuiz.mode || QuizMode.exam,
    });
  };

  const canCreateSimilarQuiz = !!lastFinishedQuiz && !isLoading;

  return {
    createQuizLikeLastFinished,
    isLoading,
    isCreatingSimilar,
    canCreateSimilarQuiz,
  };
};
