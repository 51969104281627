import { QuizMode } from '@/appTypes';
import { setInitialStateOnNewQuiz } from '@/features/Quiz/store';
import { useAppDispatch, useAppNavigate, useNotify } from '@/hooks';
import { Pages } from '@/navigation';
import { QuizGtmEvent, captureError, trackEvent, trackEventQuiz } from '@/services';
import { useAppTranslation } from '@/translation';

import { useCreateQuizApi } from '../../Home/api';

interface CreateQuizParams {
  selectedArticlesNumbers: number[];
  questionNumber: number;
  mode: QuizMode;
}

export const useCreateQuiz = () => {
  const { t } = useAppTranslation();
  const { notify } = useNotify();
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigate();

  const { createQuiz: createQuizApi, isLoading } = useCreateQuizApi();

  const createQuiz = async ({ mode, questionNumber, selectedArticlesNumbers }: CreateQuizParams) => {
    try {
      const responseData = await createQuizApi({
        relatedArticles: selectedArticlesNumbers,
        questionsAmount: questionNumber,
        mode,
      });

      if (!responseData) {
        notify('error', t('home:errorOccurredDuringCreatingQuiz'));
        return;
      }

      trackEventQuiz(QuizGtmEvent.create, {
        selectedArticlesGroups: selectedArticlesNumbers,
        questionsAmount: questionNumber,
        quizMode: mode,
      });
      trackEvent.quiz.create(mode, responseData.id, questionNumber);
      dispatch(setInitialStateOnNewQuiz({ quiz: responseData }));
      navigate(Pages.quizSolving, { id: responseData.id });
    } catch (err) {
      captureError(err);
      return;
    }
  };

  return {
    createQuiz,
    isLoading,
  };
};
